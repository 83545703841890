$primary-green: #077915;
$primary-orange: #077915;
$primary-brown: #8B4513;
$primary-blue: #005f99;
$primary-orange-2: #ff7f00;
$green-medium: #0fc024;
$white: #fff;
$gray-eighty: #ccc;
$gray-light: #999;
$gray-medium: #666;
$gray-dark: #333;
$black: #000;
$red: #e23d3d;
$gray-light-bg: #f5f5f5;
$gray-medium-bg: #f1f1f1;
$gray-dark-bg: #dddddd;
$green-light-bg: rgba(147, 220, 156, 0.3);
$primary-yellow: #ffd740;
