footer {
  color: $gray-light;
  font-size: 14px;
  text-align: center;
  padding: 32px;

  strong {
    color: $gray-medium;
  }

  .footer-links {
    margin-bottom: 24px;

    a {
      margin: 0 8px;
      color: $gray-medium;
    }
  }
}

.footer {
  background: $green-light-bg;
  padding-top: 50px;
  margin-top: 50px;
}

.logo-section {
  display: flex;
  justify-content: space-around;
}

.logos {
  display: flex;
  justify-content: center;
}

.logo-self {
  font-size: 35px;
  text-align: center;
  text-align: center;
  padding: 10px;
}

.logo-footer {
  width: 120px;
  height: 120px;
}