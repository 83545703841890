header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  -webkit-transition: background-color .5s ease;
  /* For Safari 3.1 to 6.0 */
  transition: background-color .5s ease;

  .container {
    display: flex;
    display: -webkit-flex;
    max-width: 100%;
    margin: 0 auto;
    padding: 5px 120px;

    .alignMenu {
      margin-top: auto;
      margin-left: auto;
    }
  }

  .desap {
    -moz-animation: slide 1s ease 3.5s forwards;
    -webkit-animation: slide 1s ease 3.5s forwards;
    -o-animation: slide 1s ease 3.5s forwards;
    -ms-animation: slide 1s ease 3.5s forwards;
    animation: slide 1s ease 3.5s forwards;
  }

  @media (max-width: 1126px) {
    .container {
      padding: 10px 80px;
    }
  }

  @media (max-width: 1070px) {
    .container {
      padding: 10px 10px;
    }
  }

  @media (max-width: 480px) {
    //padding: 8px 12px;
  }
}

.brand {
  display: flex;
  justify-content: space-between;

  .logo {
    width: 40px; /* Adjust the width of the logo as needed */
    height: 40px; /* Maintain aspect ratio */
    align-self: center;
  }
  
  h4 {
    margin: 0; /* Remove any default margins */
    line-height: 1; /* Ensure the title is vertically centered */
  }

  @media (max-width: 715px) {
    margin-left: 45px;
  }
}

.lastHeader {
  background-color: $white;
  color: $gray-dark;

  a {
    color: $gray-dark;
    text-shadow: none;
  }

  .brand {
    color: $gray-dark;
  }

}

@keyframes slideUp {
  0% {
    transform: translateY(30px);
    opacity: 0.5;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }

  10%,
  20% {
    transform: scale3d(0.8, 0.66, 0.66) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

.tada {
  animation-name: tada;
  animation-duration: 300ms;
  animation-fill-mode: both;
}

.dropdown-menu {
  padding: 0;
  min-width: 1rem !important;
}

.dropdown-item {
  padding: 2px 5px !important;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:80px;
	right:20px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
  transform: translatey(0px);
  animation: float 1s ease-in-out infinite;
}

.my-float{
	margin-top:16px;
  color:#FFF !important;
}