.panelnav {
  background: #212529;
  width: 200px;
  position: absolute;
  top: 0;
  bottom: 0;
}
.panelul {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;

  .paneli {
    padding: 10px;
    border-bottom: 1px $white solid;

  }
}

.panelink {
  color: $white;
}


.sectionpanel {
  background-color: $white;
  padding: 5px 25px 10px 25px;
  height: 44px;
  width: 200px;
  text-align: center;
  color: $black;
  font-size: 20px;
  i {
    font-size: 70px;
    color: #21CCFC;
  }
}

/***** RIGHT *******/

.fixpanel {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $white;
}

.panelmenu {
  height: 44px;
}

.panelmenu ul,
.panelmenu li {
	margin: 0 ;
	padding: 0;
}

.panelmenu ul {
	list-style: none;
	width: 100%;
}

.panelmenu {
	float: right;
	//margin-top: -31px;
  @media (max-width: 715px) {
    display: none;
  }
	li {
		float: left;
		position: relative;
		width:auto;
	}

	a {
		//color: $gray-dark;
		display: block;
		//text-align: center;
		text-decoration: none;
		//border-bottom: 2px solid $white;
		-webkit-transition: all .5s ease;
		-moz-transition: all .5s ease;
		-ms-transition: all .5s ease;
		-o-transition: all .5s ease;
		transition: all .5s ease;

		  @media (min-width: 995px) {
				padding: 10px 25px 10px 25px
		  }
		  @media (max-width: 994px) {
		    padding: 10px 20px;
		  }

		  @media (max-width: 819px) {
		    padding: 10px 10px;
		  }
		}

}


/* ****** PANEL *****/
.panelC {
  margin-left: 210px;
  margin-top: 50px;
  width: 80%;
}

.bPannel {
  height: 44px;
  color: $black;
  background-color: $white;

  &:hover {
    color: $white;
    background-color: $primary-green;
  }
}

.actions-space {
  height: 3em;
}



/***************** BUTTONS ******************/
.buttons-panel {

  button {
    margin-right: 10px;
  }
}


//Panel List Products
.maxTh {
  max-width: 300px;
}
.img-preview {
  max-height: 100px;
}

.options {
  margin-right: 1em;
}

.delete {
  color: red;
}

.pIcon {
  font-size: 1.5em;
  line-height: 30px;
  &:hover {
    text-shadow: 0 0 20px $primary-orange;
  }
}



/******************* Fields Order *********************/
.showFields {
  display: none;
}

.errorField {
  border-color: red;
}
