.question {
  background-color: $green-light-bg;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  //border: 1px solid $green-medium;

  @media (max-width: 991px) {
    padding: 8px;
    .container {
      padding: 0;
    }
  }
  @media (max-width: 480px) {
    padding: 8px 12px;
  }

}

.spaceBotton {
  margin-bottom: 10px;
}