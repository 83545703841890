.LoadingPage {
  font-size: 5em;
}

.uppercase {
  text-transform: uppercase;
}

h2 {
  padding: 5px;
}

h3,
h4 {
  padding: 10px 5px;
}

.container {
  margin-top: 25px;
}

.table {
  border: $gray-light-bg solid;
}

.right-content {
  float: right;
  &:hover {
    cursor: pointer;
  }
}

.leafIcon {
  color: $gray-light;
  font-size: 1em;
}

.space {
  margin-top: 20px;
}

.space-2 {
  margin-top: 40px;
}

.space-3 {
  margin-top: 60px;
}

// Tables

.thead {
  padding: 10px;
  background: $primary-green;
  //border-radius: 10px;
  color: $white;
}

tbody {
  background-color: $white;
}

tr {
  cursor: pointer;
}


.badge {
  background-color: $primary-green;
}

// List group items
.list-group-item {
  cursor: pointer;

  &:hover {
    background-color: $gray-light-bg;
    color: $primary-green !important;
    i {
      color: $primary-green;
    }
  }
  &:active {
    background-color: $green-light-bg;
  }
}

.activeList-group {
    color: $primary-green !important;
    i {
      color: $primary-green;
    }
}


.leftShop {
  margin-bottom: 50px;
  font-size: 15px;
  @media (min-width: 992px) {
    float: left;
    width: 22%;
    margin-right: 3%;
  }

}

.rightShop {
  @media (min-width: 992px) {
    //width: 75%;
  }
}


// FOOTER
.userIcon {
  font-size: 1.2em;
  line-height: 40px;
  color: $primary-green;
}

p {
  font-size: 1.1em;
}

a {
  text-decoration: none !important;
  color: $gray-dark;
  &:hover{
    color: $primary-green;
  }
}

.inFooter {
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    color: $primary-green !important;

  }
}

// About us

.none-mobile {
  display: none;
  
  @media (max-width: 767px) {
    display: block;
  }


}

.bold {
  font-weight: bold;
  font-style: normal;
}

.about-list {
  margin-left: 20px;

  li {
    padding-bottom: 5px;
  }
}

.primary-green {
  color: $primary-green;
}

// PANEL

.panelImg {
  width: 120px;

}

.ordTable tr th {
  cursor: auto !important;
}

.paymenInfo {
  background-color: $primary-green;
}
