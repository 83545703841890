.wallpaperHomeopatia {
    position: relative;
    background: url("./assets/homeopatia.jpg") top center;
    height: 596px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    .textAbout {
        color: $white;
    }

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: rgba(220, 222, 219, 0.4);
        z-index: 1;

    }

}

#about {
    position: relative;
    z-index: 2;
    font-size: 4em;
    color: black;
    text-shadow: 2px 0 0 black;


    @media (max-width: 991px) {
        font-size: 3.2em;
    }

    @media (max-width: 768px) {
        font-size: 2.9em;
    }

    @media (max-width: 696px) {
        font-size: 2.5em;
    }

}

/******* CONTACTO ********/

.wallpaperContacto {
    position: relative;
    background: url("./assets/home.jpg") center center;
    height: 596px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: rgb(248, 248, 192, 0.2);
        z-index: 1;

    }
}

.hover-orange:hover {
    color: $primary-orange-2;
    cursor: pointer !important;
}

.hover-blue:hover {
    color: $primary-blue;
}

.hover-brown:hover {
    color: $primary-brown;
}

/******* PRODUCTOS ********/

.wallpaperProductos {
    position: relative;
    background: url("./assets/productos.jpg") center center;
    height: 596px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: rgba(230, 230, 214, 0.5);
        z-index: 1;

    }
}

.carousel-container-catalogo {
    width: 90%;
    padding-left: 10%;
    align-self: center;

    @media (max-width: 994px) {
        padding-left: 8%;
    }
}

.carousel-item-catalogo img {
    height: 596px;

    @media (max-width: 994px) {
        width: 100%;
        height: auto;
    }
}


.carousel-item-preview img {
    height: 30px;
}

.slick-dots {
    position: unset !important;
    bottom: 0 !important;
}